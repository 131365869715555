<template>
  <div :id="id"
       class="sitebuilder-element-container"
       element="BlogRssComponent"
       :data-hide-desktop="!this.internal_config.showDesktop"
       :data-hide-mobile="!this.internal_config.showMobile"
       :config-properties="JSON.stringify(internal_config)" :style="style()">

        <a href="/rss" target="_blank">
        <img style="width: 100%; height: 100%; cursor: pointer" :src="`${this.blogUrl}/images/rss/rss-feed-${internal_config.theme}.svg`" alt="">
        </a>

  </div>
</template>

<script>
import { mixinBlog } from '../../../../js/mixins/blog';
import elementData from './Model';

export default {
  name: 'BlogRssComponent',
  props: ['config', 'id'],
  mixins: [mixinBlog],
  data() {
    return elementData.data();
  },
  methods: {
    build(config) {
      this.internal_config = {...this.internal_config, ...config};
    },
    animation() {
      return false;
    },
    style() {
      return `width: ${this.internal_config.width};
               height:  ${this.internal_config.width};
               z-index: ${this.internal_config.zIndex};
               margin: 0 auto;
               padding-top: ${this.internal_config.paddingTop};
               padding-left: ${this.internal_config.paddingLeft};
               padding-right: ${this.internal_config.paddingRight};
               padding-bottom: ${this.internal_config.paddingBottom};
               left:  ${this.internal_config.left};
               top: ${this.internal_config.top}`;
    }
  },
  mounted() {
    this.build(this.$props.config);
  }
};
</script>

<style>

</style>
