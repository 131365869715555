export const mixinSocialProof = {
    data() {
      return {
        hostMap: {
          '.sitebuilder-publication.kub.app.builderall.io': {
            socialProof: 'https://socialproof.kub.app.builderall.io'
          },

          '.sitebuilder-publication.kub.app.builderall.info': {
            socialProof: 'https://socialproof.kub.app.builderall.info'     
          },
          
          'default': {
            socialProof: 'https://proof.builderall.com'            
          }
        }
      };
    },
    
    methods: {
      getSocialProofUrl(host = null, id) {        

        if (id == null) throw new Error('the id parameter cannot be null');
        
        if (host !== null) {
          const foundKey   = Object.keys(this.hostMap).find(key => host.includes(key));
          const hostConfig = this.hostMap[foundKey] || this.hostMap['default'];
          let url = '';

          switch(hostConfig.socialProof) {
            case 'https://socialproof.kub.app.builderall.io': 
              url = `${hostConfig.socialProof}/js/show_notification.js?id=${id}&env=develop`;
              break;
            case 'https://socialproof.kub.app.builderall.info':
              url = `${hostConfig.socialProof}/js/show_notification.js?id=${id}&env=homolog`;
              break;
            default:
              url = `${hostConfig.socialProof}/js/show_notification.js?id=${id}`;
              break;
          }
          
          return url;
        }

        return `https://proof.builderall.com/js/show_notification.js?id=${id}`;

      }
    }
  };
  