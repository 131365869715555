export const mixinNotify = {
  data() {
    return {
      hostMap: {
        '.sitebuilder-publication.kub.app.builderall.io': {
          notify: 'https://notify.kub.app.builderall.io'
        },

        '.sitebuilder-publication.kub.app.builderall.info': {
          notify: 'https://notify.kub.app.builderall.info'
        },

        'default': {
          notify: 'https://notify.builderall.com'
        }
      }
    }
  },
  methods: {
    getNotifyUrl() {
      const host       = window.location.host
      const foundKey   = Object.keys(this.hostMap).find(key => host.includes(key))
      const hostConfig = this.hostMap[foundKey] || this.hostMap['default']

      return hostConfig.notify
    }
  }
}
