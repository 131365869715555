export const mixinBlog = {
  data() {
    return {
      hostMap: {
        '.sitebuilder-publication.kub.app.builderall.io': {
          blog: 'https://blog.kub.app.builderall.io'
        },

        '.sitebuilder-publication.kub.app.builderall.info': {
          blog: 'https://blog.kub.app.builderall.info'
        },

        'default': {
          blog: 'https://blog.builderall.com'
        }
      }
    }
  },
  
  computed: {
    blogUrl() {
      return this.getBlogUrl();
    }
  },

  methods: {
    getBlogUrl() {
      const host       = window.location.host
      const foundKey   = Object.keys(this.hostMap).find(key => host.includes(key))
      const hostConfig = this.hostMap[foundKey] || this.hostMap['default']

      return hostConfig.blog
    }
  }
}
