export const apiUrl = () => {
    const domain         = window.location.hostname
    const domainMappings = [
        { pattern: /builderall\.local$/,     url: "https://s-checkout.builderall.local" },
        { pattern: /builderall\.io$/,        url: "https://supercheckout.kub.app.builderall.io" },
        { pattern: /builderall\.info$/,      url: "https://supercheckout.kub.app.builderall.info" },
        { pattern: /builderall\.(com|net)$/, url: "https://s-checkout.builderall.com" }
    ];

    const match = domainMappings.find(({ pattern }) => pattern.test(domain));
    
    return match ? match.url : window.location.origin + '/sc-bridge';    
}
