<template>
  <div class="wrapper-comments">
    <h5 v-text="$t('ct-comments')" />

    <!-- Comentários -->
    <div v-if="is_logged" class="wrapper-comments__textarea" >
      <textarea
        :placeholder="`${$t('ct-add-comments')}...`"
        v-model="comment"
      />
      <div class="wrapper-comments__textarea-options">
        <small
          v-if="SelectedComment.id"
          v-text="`${$t('ct-answering-comment')} ${SelectedComment.name}`"
        />
        <div class="wrapper-comments__textarea-buttons" >
          <button
            v-show="SelectedComment.id"
            @click="cancelReply"
          >
          {{ $t('ct-cancel-publication')}}
          </button>
          <button
            :class="`wrapper-comments__textarea-buttons__theme-${theme}`"
            v-text="$t('ct-comment')"
            @click="handleComment"
          />
        </div>
      </div>
    </div>
    <!-- Comentários -->

    <!-- Login -->
    <div v-else>
      <simple-theme v-if="theme === 1" :url="article.url"  />
      <dark-green-theme v-if="theme === 2" :url="article.url"  />
      <gradient-theme v-if="theme === 3" :url="article.url"  />
    </div>
    <!-- Login -->

    <!-- Wait for approval -->
    <div v-if="approval" class="alert alert-light alert-dismissible fade show mt-3 wrapper-comments__alert" role="alert">
      {{ $t('ct-approve-comment') }}
      <button
        type="button"
        class="close"
        @click="approval = false"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <!-- Wait for approval -->

    <!-- Comments Box -->
    <ul class="wrapper-comments__list">
      <li
        v-for="( comment, index ) in comments"
        :key="index"
      >
        <div class="wrapper-comments__main">
          <div class="wrapper-comments__avatar">
            <img
              src="/static/user-avatar.png"
              alt=""
            />
          </div>

          <div class="wrapper-comments__box">
            <div class="wrapper-comments__box-head">
              <div class="wrapper-comments__box-head__info">
                <h6
                  :class="comment.is_author ? 'by-author' : ''"
                  v-text="comment.name"
                />
                <span
                  v-text="handleDate(comment.created_at)"
                />
              </div>
              <div class="wrapper-comments__box-head__options">
                {{  !!comment.likes.length && !comment.deleted_by_author ? comment.likes.length : '' }}
                <i
                  v-if="!comment.deleted_by_author"
                  :style="comment.liked ? 'color: #ed4957' : ''"
                  class="fa fa-heart"
                  @click="handleLike(comment)"
                />
                <i
                  v-if="is_logged && !comment.deleted_by_author"
                  class="fa fa-reply"
                  data-toggle="tooltip"
                  data-placement="top"
                  :title="false ? 'Aguarde para comentar novamente!' : '' "
                  :disabled="false? 'disabled' : false"
                  @click="handleReply(comment)"
                />
                <!-- Delete -->
                <div :id="`delete-comment-${comment.id}`" class="modal wrapper-comments__box-head__options__delete">
                  <span>{{ $t('ct-delete-comments') }}</span>
                  <div class="mt-4">
                    <button class="btn btn-light" data-dismiss="modal">{{ $t('ct-cancel-publication') }}</button>
                    <button class="btn btn-outline-danger ml-2" @click="handleDelete(comment.id)">{{ $t('ct-confirm') }}</button>
                  </div>
                </div>
                <!-- Delete -->
                <i
                  v-if="comment.email === config.email && is_logged && !comment.deleted_by_author"
                  class="fas fa-trash"
                  data-toggle="modal" :data-target="`#delete-comment-${comment.id}`"
                />
              </div>
            </div>
            <div
                v-if="comment.deleted_by_author"
                class="wrapper-comments__box-content"
                v-text="'deleted by author'"
              />
              <div
                v-if="comment.deleted_by_owner"
                class="wrapper-comments__box-content"
                v-text="'deleted by owner'"
              />
              <div
                v-if="!comment.deleted_by_author && !comment.deleted_by_owner"
                class="wrapper-comments__box-content"
                v-text=" comment.comment"
              />
          </div>
        </div>

        <ul class="wrapper-comments__list wrapper-comments__reply">
          <li
            v-for="(reply, index) in comment.replys"
            :key="index"
          >
            <div class="wrapper-comments__reply-avatar">
              <img
                src="/static/user-avatar.png"
                alt=""
              />
            </div>

            <div class="wrapper-comments__box">
              <div class="wrapper-comments__box-head">
                <div class="wrapper-comments__box-head__info">
                  <h6
                    :class="reply.is_author ? 'by-author' : ''"
                    v-text="reply.name"
                  />
                  <span
                    v-text="handleDate(reply.created_at)"
                  />
                </div>
                <div class="wrapper-comments__box-head__options">
                  {{  !!reply.likes.length && !reply.deleted_by_author ? reply.likes.length : '' }}
                  <i
                    v-if="!reply.deleted_by_author"
                    :style="reply.liked ? 'color: red' : ''"
                    class="fa fa-heart"
                    @click="handleLike(reply)"
                  />

                  <!-- Delete -->
                  <div :id="`delete-comment-${reply.id}`" class="modal wrapper-comments__box-head__options__delete">
                    <span v-text="$t('ct-delete-comments')" />
                    <div class="mt-4">
                      <button
                        class="btn btn-light"
                        data-dismiss="modal"
                        v-text="$t('ct-cancel-publication')"
                      />
                      <button
                        class="btn btn-outline-danger ml-2"
                        v-text="$t('ct-confirm')"
                        @click="handleDelete(reply.id)"
                      />
                    </div>
                  </div>
                  <!-- Delete -->

                  <i
                    v-if="reply.email === config.email && !reply.deleted_by_author && is_logged"
                    class="fas fa-trash"
                    data-toggle="modal"
                    :data-target="`#delete-comment-${reply.id}`"
                  />
                </div>
              </div>
              <div
                v-if="reply.deleted_by_author"
                class="wrapper-comments__box-content"
                v-text="'deleted by author'"
              />
              <div
                v-if="reply.deleted_by_owner"
                class="wrapper-comments__box-content"
                v-text=" reply.comment"
              />
              <div
                v-if="!reply.deleted_by_author && !reply.deleted_by_owner"
                class="wrapper-comments__box-content"
                v-text=" reply.comment"
              />
            </div>
          </li>
        </ul>
      </li>
    </ul>
    <!-- Comments Box -->

    <button
      v-show="showMore"
      class="wrapper-comments__more-comments"
      v-text="$t('ct-more-comments')"
      @click="moreComments()"
    />
  </div>
</template>

<script>

/** Themes */
import SimpleTheme from './comments_theme/Simple'
import DarkGreenTheme from './comments_theme/DarkGreen'
import GradientTheme from './comments_theme/Gradient'

import moment from 'moment/moment';
import axios from 'axios';
import { mixinBlog } from '../../../../../../js/mixins/blog';

export default {
  props: ['is_logged', 'article'],
  mixins: [mixinBlog],
  components: {
    SimpleTheme,
    DarkGreenTheme,
    GradientTheme,
  },
  data() {
    return {
      comments: [],
      user: {
        id: 1,
        name: 'John Doe',
        email: 'localhost@localhost.com'
      },
      comment: '',
      SelectedComment: {},
      approval: false,
      theme: 0,
      reply_mode: false,
      count: 10,
      showMore: false,
      config: {
        url: window.location.pathname.replace('/', ''),
        website_id: 'NjMwNDIw',
        name: 'Autor',
        email: 'marinha@marinha.com'
      }
    }
  },

  methods: {
    getComments() {

      axios.get(`${this.blogUrl}/comments/get?website_id=${this.config.website_id}&url=${this.config.url.split('blog/').join('')}&email=${this.config.email}&count=${this.count}`)
      .then( res => {
          this.comments = res.data.comments;
          this.showMore = res.data.more
          this.theme = res.data.theme
      } )
    },
    moreComments(){
      this.count = this.count + 10;
      this.getComments();
    },
    cancelReply(){
      this.SelectedComment = {}
    },
    handleDelete(id) {


    if(!this.is_logged)
        return false;


      let vm = this;
      let obj = {
        id: id,
        type: 'delete',
        email: JSON.parse(localStorage.getItem('login-user')).email
      }

      $(`#delete-comment-${id}`).modal('hide')

      axios.post(`${this.blogUrl}/comments/update`, obj)
        .then( res => {
            vm.getComments();
        } )

    },
    handleLike(comment) {

      let id = comment.id
      if(!this.is_logged || comment.deleted_by_author || comment.deleted_by_owner )
        return false;

      let vm = this;
      let obj = {
        id: id,
        type: 'like',
        name: JSON.parse(localStorage.getItem('login-user')).name,
        email: JSON.parse(localStorage.getItem('login-user')).email
      }

      axios.post(`${this.blogUrl}/comments/update`, obj)
        .then( res => {
            vm.getComments();
        } )
    },
    handleReply( comment ) {


      if(!this.is_logged)
        return false;


      const [ addComment ] = $('.wrapper-comments__textarea');
      addComment.scrollIntoView({ block: "end", behavior: "smooth" });

      this.SelectedComment = comment ;
      this.reply_mode = true;
    },
    handleComment() {


      if(!this.is_logged)
        return false;


      let vm = this;

      let obj = {
        user : { email: JSON.parse(localStorage.getItem('login-user')).email, name: JSON.parse(localStorage.getItem('login-user')).name },
        comment: this.comment,
        like: 0,
        replys: [],
        url: this.config.url,
        website_id: this.config.website_id
      };

      if(this.reply_mode){
        obj.reply_id =  this.SelectedComment.id;
      }

      axios.post(`${this.blogUrl}/comments/store`, obj)
        .then( res => {
          if(!res.data.allowed){
            this.approval = true
          }
          vm.comment = '';
          vm.getComments();
        } )
    },
    handleDate(date) {
      const locale = window.navigator.userLanguage || window.navigator.language;
      let data = new Date();
      moment.locale(locale)
      return moment(date).add(moment(date).utcOffset(), 'minutes').fromNow();
    }
  },
  mounted() {      
      if(this.is_logged){
        this.config.name = JSON.parse(localStorage.getItem('login-user')).name;
        this.config.email = JSON.parse(localStorage.getItem('login-user')).email;
      }else{
         this.config.name = 'not-logged';
         this.config.email = 'not-logged';
      }

      this.config.website_id = OPTIONS_INIT_SITE.options.w_code;
        this.getComments();
  }
}
</script>

<style lang="scss" scoped>

.wrapper-comments {
  margin: 60px auto 15px;
  width: 100%;

  h5 {
    margin-bottom: 20px;
  }

  &__login {
    display: flex;
    flex-direction: column;
    align-items: center;

    background: rgba(0,0,0, 0.1);
    padding: 40px;

    span {
      font-family: 'Muli', sans-serif;
      font-size: 20px;
      font-weight: 900;
      color: #0294FF;

      margin-bottom: 20px;
    }

    div {
      width: 100%;
      display: flex;
      justify-content: space-around;
    }

    button {
      font-family: 'Lato', sans-serif;
      text-transform: uppercase;
      font-weight: 900;

      border: 0;
      padding: 20px;
      border-radius: 50px;

      transition: all 0.5s;

      &:nth-of-type(1) {
        background: #0294FF;
        color: #FFFFFF;

        &:hover {
          background: #FFFFFF;
          color: #0294FF;
        }
      }

      &:nth-of-type(2) {
        background: #FFFFFF;
        color: #0294FF;

        &:hover {
          background: #0294FF;
          color: #FFFFFF;
        }
      }
    }
  }

  &__textarea {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    width: 100%;

    textarea {
      width: 100%;
      height: 100px;

      resize: none;

      border: 2px solid #DDDDDD;
      color: #656565;
      border-radius: 5px;
    }

    &-options {
      display: flex;
      justify-content: space-between;

      width: 100%;
      margin-top: 10px;
    }

    &-buttons {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      width: 100%;

      button {
        width: 100px;
        border: 0;
        border-radius: 100px;

        padding: 10px;
        transition: all 0.3s;

        &:nth-of-type(1) {
          background: #EFEFEF;

          margin-bottom: 10px;

          &:hover {
            background: #FFFFFF;
            color: #0294FF;
          }
        }
      }

      &__theme-1 {
        color: #FFFFFF;
        background: #0294FF;

        &:hover {
          background: #2E81FC;
        }
      }

      &__theme-2 {
        color: #FFFFFF;
        background: #444857;

        &:hover {
          background: #5a6074;
        }
      }

      &__theme-3 {
        color: #FFFFFF;
        background: #5844ad;

      &:hover {
        background: #b897e5;
      }
    }
  }
}

  &__alert {
    background: #A6D3FE;
    border-left: 10px solid #0080FC;

    color: #080D3ACC;
  }

  &__list {
    padding: 0;
    margin-top: 30px;

    position: relative;

    &::before {
        content: '';
        width: 2px;
        height: 100%;
        background: #c7cacb;
        position: absolute;
        left: 32px;
        top: 0;
      }

      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 27px;
        width: 7px;
        height: 7px;
      }

    li {
      display: block;
      position: relative;
      margin-bottom: 15px;

      &::after {
        content: '';
        display: block;
        clear: both;
        height: 0;
        width: 0;
      }
    }
  }

  &__main {
    display: flex;

    &::after {
      content: '';
      width: 0;
      height: 0;
      display: block;
      clear: both;
    }
  }

  &__avatar img {
    width: 65px;
    height: 65px;
  }

  &__box {
    position: relative;
    width: 100%;
    margin-left: 20px;

    -webkit-box-shadow: 0 1px 1px rgba(0,0,0,0.15);
    -moz-box-shadow: 0 1px 1px rgba(0,0,0,0.15);
    box-shadow: 0 1px 1px rgba(0,0,0,0.15);

    &::before,
    &::after {
      content: '';
      height: 0;
      width: 0;
      position: absolute;
      display: block;
      border-width: 10px 12px 10px 0;
      border-style: solid;
      border-color: transparent #FCFCFC;
      top: 8px;
      left: -10px;
    }

    &::before {
      border-width: 11px 13px 11px 0;
      border-color: transparent rgba(0,0,0,0.05);
      left: -12px;
    }

    &-head {
      display: flex;
      flex-direction: column;

      background: #FCFCFC;
      padding: 10px 12px;

      border-bottom: 1px solid #E5E5E5;
      border-radius: 5px 5px 0 0;

      &__info {
        width: 100%;

        display: flex;

        h6 {
          color: #283035 !important;
          font-size: 14px !important;

          &.by-author {
            &::after {
              content: 'autor';
              background: #03658c;
              color: #FFF;
              font-size: 12px;
              padding: 3px 5px;
              margin-left: 10px;
              -webkit-border-radius: 3px;
              -moz-border-radius: 3px;
              border-radius: 3px;
            }
          }
        }

        span {
          color: #999;
          font-size: 13px;

          margin-left: 10px;
        }
      }

      &__options {
        display: flex;
        justify-content: center;
        align-items: center;

        font-family: 'Muli';
        font-weight: 900;
        color: rgb(237, 73, 87);

        &__delete {
          position: absolute;
          top: -110px;
          right: 0;
          left: unset;

          width: auto;
          height: auto;

          background: #FCFCFC;
          border-radius: 5px;
          border: 1px solid #E5E5E5;

          padding: 20px;
        }

        i {
          margin-left: 14px;
          color: #A6A6A6;
          font-size: 15px;

          transition: color 0.3s ease;

          cursor: pointer;

          &[disabled="disabled"] {
            cursor: not-allowed !important;
          }

          &:hover {
            color: #03658c;
          }
        }
      }
    }

    &-content {
      background: #FFF;
      padding: 12px;
      font-size: 15px;
      color: #595959;
      -webkit-border-radius: 0 0 4px 4px;
      -moz-border-radius: 0 0 4px 4px;
      border-radius: 0 0 4px 4px;


      white-space: pre-wrap;
    }
  }

  &__more-comments {
    width: 100%;

    color: #737373;
    background: #FCFCFC;
    border: 2px solid #DDDDDD;

    padding: 10px;
    border-radius: 3px;
  }

  &__reply {
    margin-top: 15px;
    padding: 20px;


    &::before,
    &::after {
      display: none;
    }

    & .wrapper-comments__box {
      width: 350px;
    }

    &-avatar img {
      width: 50px;
      height: 50px;
    }


    li {
      display: flex !important;
    }
  }
}

@media (min-width: 480px) {
  .wrapper-comments {
    &__box {
      &-head {
        flex-direction: row;
      }
    }

    &__list {
      padding-left: 40px;
    }
  }
}

@media (min-width: 600px) {
  .wrapper-comments {
    &__textarea {
      &-buttons {
        flex-direction: row;
        justify-content: flex-end;

        button:nth-of-type(1) {
          margin: 0px 20px;
        }
      }
    }

    &__list {

      &::before {
        content: '';
        width: 2px;
        height: 100%;
        background: #c7cacb;
        position: absolute;
        left: 32px;
        top: 0;
      }

      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 27px;
        width: 7px;
        height: 7px;
      }

      li {
        display: block;
        position: relative;
        margin-bottom: 15px;

        &::after {
          content: '';
          display: block;
          clear: both;
          height: 0;
          width: 0;
        }
      }
    }

    &__reply {
      padding-left: 88px;

      li {
        &::before {
          content: '';
          width: 100px;
          height: 2px;
          background: #c7cacb;
          position: absolute;
          top: 25px;
          left: -95px;
        }
      }

    }
  }
}

@media (min-width: 1024px) {
  .wrapper-comments {
    width: 600px;
  }
}
</style>
