<template>
  

 <div :id="id"
       class="sitebuilder-element-container"
       element="BlogFeedNewsComponent"
       :data-hide-desktop="!this.internal_config.showDesktop"
       :data-hide-mobile="!this.internal_config.showMobile"
       :config-properties="JSON.stringify(internal_config)" :style="style()">


    <div v-if="internal_config.theme == 1 || internal_config.theme == 2 || internal_config.theme == 3">
        <theme-1 :options="allOptions"></theme-1>
    </div>
    <div v-if="internal_config.theme == 4">
        <theme-grid :options="allOptions"></theme-grid>
    </div>
      <div v-if="internal_config.theme == 5">
        <theme-grid-simple :options="allOptions"></theme-grid-simple>
    </div>
    <div v-if="internal_config.theme == 6">
        <theme-hexagon :options="allOptions"></theme-hexagon>
    </div>
   


    <div v-if="paginator.show" class="row">
        <div class="col-12">
            <p class="float-right">page: {{ paginator.actual_page }}</p>
        </div>
        <div  class="col-12">
            <div class="float-right">
                <nav aria-label="Page navigation example">
                
                <ul class="pagination" >
                    <li v-if="paginator.actual_page > 3" @click="changePage('first')" class="page-item"><a :style="{color: internal_config.colorReadMore}" class="page-link" href="#">First</a></li>
                    <li v-if="paginator.actual_page > 1" @click="changePage('previous')" class="page-item"><a :style="{color: internal_config.colorReadMore}" class="page-link" href="#">Previous</a></li>
                    <li v-for="index in paginator.pages" :key="index" @click="changePage(index)" class="page-item"><a :style="{color: internal_config.colorReadMore}" class="page-link" href="#"> {{ index }} </a></li>
                    <li v-if="paginator.actual_page < paginator.last_page" @click="changePage('next')" class="page-item"><a :style="{color: internal_config.colorReadMore}" class="page-link" href="#">Next</a></li>
                    <li v-if="paginator.actual_page < paginator.last_page" @click="changePage('last')" class="page-item"><a :style="{color: internal_config.colorReadMore}" class="page-link" href="#">Last</a></li>
                </ul>
                </nav>
            </div>
        </div>
    </div>

   
    

 
  </div>

</template>


<script>

import elementData from './Model';
import Theme1 from "./themes/Theme1";
import ThemeGrid from "./themes/ThemeGrid";
import ThemeGridSimple from "./themes/ThemeGridSimple";
import ThemeHexagon from "./themes/Hexagon";


import moment from 'moment/moment';

import axios from 'axios';
import { mixinBlog } from '../../../../js/mixins/blog';

export default {
    name: 'BlogFeedNewsComponent',
    props: ['config', 'id'],
    mixins: [mixinBlog],
    components: {  Theme1, ThemeGrid, ThemeGridSimple, ThemeHexagon },
    data() {
			return elementData.data();
		},
    methods: {
        focusElement(e) {
            this.$root.$emit("focusElement", this);
            this.$root.$emit("handlerElement", this);

            e.stopPropagation();
            e.preventDefault();
        },
       
        build(config) {
            this.internal_config = config;
        },
         animation() {
        return false;
        },
       
        //style methods

        style(){
           let {
        left,
        zIndex,
        paddingTop,
        paddingLeft,
        paddingRight,
        paddingBottom,
        top
      } = this.internal_config;

      let style = `
                     left:  ${left};
                     z-index: ${zIndex};
                     max-width: ${this.internal_config.maxWidth};
                     width: ${this.internal_config.width};
                     margin: 0 auto;
                     padding-top: ${paddingTop};
                     padding-left: ${paddingLeft};
                     padding-right: ${paddingRight};
                     padding-bottom: ${paddingBottom};
					 top: ${top}`;

      return style; 
        },

    getkey(){

        let vm = this;

        let url = `${this.blogUrl}/get-key?website_id=${window.OPTIONS_INIT_SITE.options.w_code}`;

        axios.get(url)
        .then((res) => {
            this.viewConfig = res.data;

             if(localStorage.getItem('utc_time_' + this.viewConfig.key) == null){
                    localStorage.setItem('utc_time_' + this.viewConfig.key, moment().utc().unix());
             }
            vm.getPosts(this.viewConfig.key);
        })

    },

    

    getPosts(key = false){
       let vm = this;
       
        if(this.paginator.actual_page == 'previous' || this.paginator.actual_page < 1){
            this.paginator.actual_page = 1;
        }
         if(this.paginator.actual_page > this.paginator.last_page){
            this.paginator.actual_page = this.paginator.last_page;
        }

        let perPage = (typeof this.internal_config.perPage != 'undefined') ? this.internal_config.perPage : 8;
        // perPage = 2;


        //montagem url
        let url = `${this.blogUrl}/get-news?perpage=${perPage}&page=${this.paginator.actual_page}&website_id=${window.OPTIONS_INIT_SITE.options.w_code}`;

        //verifica se é busca de termo
        if(window.location.pathname === '/s'){
            let queryString = window.location.search;
            let urlParams = new URLSearchParams(queryString);
            let search = urlParams.get('query');
            
            if(search !== null)
                url = url + '&search=' + search;
        }

        //verifica se é busca de categoria
        if(window.location.pathname.indexOf('/c/') !== -1){
            let search = window.location.pathname.replace('/c/', '');

            if(search !== null)
                url = url + '&category=' + search;

        }


        //verifica se é busca de categoria
        if(window.location.pathname.indexOf('/a/') !== -1){
            let search = window.location.pathname.replace('/a/', '');

            if(search !== null)
                url = url + '&author=' + search;

        }



        if(key){
            url = url + '&utc_time=' + localStorage.getItem('utc_time_' + key);
        }


        console.log(url);

        let elementBounding = vm.$parent.$el.getBoundingClientRect();
        let top = $(vm.$parent.$el).position().top;
        let left = $(vm.$parent.$el).offset().left;
        let width = elementBounding.width;
      
        let originalHeingEl = $(vm.$el).height();

        axios.get(url)
        .then( (res) => {
           
            let result = res.data.data;


            if(result.length > 0){
                this.paginator.show = true;
            }
            
            this.paginator.last_page = res.data.last_page;


            /**
             * escrevendo pra não esquecer
             * até a 3 começa da 1 e vai até a 5
             * a partir da 3 pagina atual -2 e pagina atual +2
             * se a ultima pagina +2 for maior que a ultima pagina retornada, ultima pagina = ultimo, primeira = ultima -5
             * se primeira pagina for menor que 1 iguala-se a 1
             * se ultima pagina = 1, não mostra paginação
             */
          
            let initial_page =  (this.paginator.actual_page >= 3) ? this.paginator.actual_page -2 : 1
            let last_page = (this.paginator.actual_page <= 3) ? 5 : this.paginator.actual_page + 2 ;
        

            if(last_page > this.paginator.last_page){
                last_page = this.paginator.last_page;
                initial_page = this.paginator.last_page - 5;
            }


            if(initial_page < 1){
                initial_page = 1;
            }

            if(last_page == 1){
                this.paginator.show = false;
            }

            this.paginator.pages = [];
            for(; initial_page <= last_page ; initial_page++){
                this.paginator.pages.push(initial_page)
            }

            vm.posts = result;

            // for(let i = 1; i < 10; i++){
                // setTimeout(function(){
                //     let valueChanged =  $(vm.$el).height() - originalHeingEl;

                //     if(vm.valueChanged != valueChanged){
                //         vm.valueChanged = valueChanged;
                //         if (typeof vm.$parent.$parent.changeHeight !== 'undefined') {
                //             vm.$parent.$parent.changeHeight(valueChanged, true, vm.$parent.id, top, left, width);
                //         } else {
                //             vm.$parent.changeHeight(valueChanged, true, vm.$parent.id, top, left, width);
                //         }
                //     }

                    

                // }, 2000)
            //  }
            
        } ) 
    },



    changePage(value){


        switch(value) {
            case 'previous': {
                  this.paginator.actual_page--;
                  break;
            }
              
            case 'next': {
                  this.paginator.actual_page++;
                  break;
            }

            case 'first': {
                  this.paginator.actual_page =1;
                  break;
            }

            case 'last': {
                  this.paginator.actual_page = this.paginator.last_page;
                  break;
            }

            default: {
                this.paginator.actual_page = value;
                break;
            }
        }
       
        this.getkey();
    },

    
    },

    created() {
        let device, childs, id, config;
        ({ device, childs, id, config } = this.$props);
        this.internal_config = config;
        this.device = device;
        if (typeof id !== "undefined") {
            this.id = id;
        }
        this.internal_config = window.mergeRecursive(
        this.props,
        this.$props.config
        );
  },
    mounted(){
        let vm = this;

        this.getkey();
        // this.getPosts();

        this.build(this.$props.config);
         this.$root.$on('setConfigElement', (id, obj) => {
            if(this.id == id){
            this.config = obj;
            this.internal_config = obj;
            }
        });

        

         let objIds = [];
            $("[id]").each(function() {
            var ids = $('[id="' + this.id + '"]');
            if (ids.length > 1 && ids[0] == this && parseInt(this.id))
                objIds.push(this.id);
            });
        if (objIds.includes(this.id)) {
        this.id = Date.now() + parseInt(Math.random() * 10000);
        }
    },
     computed:{
         allOptions(){

        //   START POSTS
            let i = 0, posts = [];
            this.posts.forEach(element => {

                    
                element.readEnable = true;
                element.number = i;
                // element.categories = element.flags;
                element.date_show = moment(String(element.scheduled_date)).add(moment().utcOffset(), 'minutes').format(this.internal_config.dateFormat)

                if(element.date_cycle  == 'Dripping' ){
                 element.date_show = moment().add(element.dripping_total_seconds, 'seconds').format(this.viewConfig.date_format_preview_news);
                 element.readEnable = false;
                }

                if(element.date_cycle  == 'Scheduled' ){
                 element.date_show = moment(String(element.scheduled_date)).add(moment().utcOffset(), 'minutes').format(this.viewConfig.date_format_preview_news);
                  element.readEnable = false;
                }

                  if(element.thumbnail == null || element.thumbnail == 'null'){
                    element.thumbnail = 'https://loremflickr.com/320/240';
                }
            
                i++;
            
                posts.push(element);
                
            });

        //  END POSTS

        // START TITLESTYLE

         let titleStyle = `font-family:  ${this.internal_config.titleStyle.family};
                  font-size: ${this.internal_config.titleStyle.size};
                  text-align: ${this.internal_config.titleStyle.align};
                  font-weight: ${this.internal_config.titleStyle.weight};
                  font-style: ${this.internal_config.titleStyle.style};
                  text-decoration: ${this.internal_config.titleStyle.stroke};
                  color: ${this.internal_config.titleStyle.color}`;


         let titleStyleMobile = `font-family:  ${this.internal_config.titleStyle.family};
                 font-size: ${this.internal_config.titleStyle.sizeMobile} !important;
                 line-height: ${this.internal_config.titleStyle.sizeMobile} !important;
                 text-align: ${this.internal_config.titleStyle.align};
                 text-decoration: ${this.internal_config.titleStyle.stroke};
                 font-weight: ${this.internal_config.titleStyle.weight};
                 font-style: ${this.internal_config.titleStyle.style};
                 color:${this.internal_config.titleStyle.color}`;
        //END TITLESTYLE

        //START CONTENT STYLE

        let contentStyle = `font-family:  ${this.internal_config.contentStyle.family};
                  font-size: ${this.internal_config.contentStyle.size};
                  text-align: ${this.internal_config.contentStyle.align};
                  font-weight: ${this.internal_config.contentStyle.weight};
                  font-style: ${this.internal_config.contentStyle.style};
                  text-decoration: ${this.internal_config.contentStyle.stroke};
                  height: ${this.internal_config.contentStyle.height};
                  overflow: hidden;
                  margin-top: 15px;
                  color: ${this.internal_config.contentStyle.color}`;


        let contentStyleMobile = `font-family:  ${this.internal_config.contentStyle.family};
                 font-size: ${this.internal_config.contentStyle.sizeMobile} !important;
                 line-height: ${this.internal_config.contentStyle.sizeMobile} !important;
                 text-align: ${this.internal_config.contentStyle.align};
                 text-decoration: ${this.internal_config.contentStyle.stroke};
                 font-weight: ${this.internal_config.contentStyle.weight};
                 font-style: ${this.internal_config.contentStyle.style};
                 color:${this.internal_config.contentStyle.color}`;

        //END CONTENT STYLE

            return {
                posts                   : posts,
                theme                   : this.internal_config.theme,
                titleStyle              : titleStyle,
                titleStyleMobile        : titleStyleMobile,
                contentStyle            : contentStyle,
                contentStyleMobile      : contentStyleMobile,
                cardBgColor             : this.internal_config.cardBgColor,
                colorReadMore           : this.internal_config.colorReadMore,
                textReadMore            : this.internal_config.textReadMore,
                colorDetails            : this.internal_config.colorDetails,
            };
      },
       filteredposts(){
           let i = 0 ;
            let result = [];
           this.posts.forEach(element => {
                element.number = i;
                // element.categories = element.flags;
                element.date_show = moment(String(element.created_at)).format(this.internal_config.dateFormat)
                i++;
                // element.date_show = element.date_create;
     
                result.push(element);
                
            });

            return result;

            //  let search = this.postSearch.toLowerCase().trim();
            //     if (!search) return result;
            //         return result.filter(b => b.title.toLowerCase().indexOf(search) > -1);


      }

    },
}
</script>

<style>


</style>