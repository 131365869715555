<template>
  <div :id="id"
       class="sitebuilder-element-container"
       element="VideoComponent"
       :data-hide-desktop="!this.internal_config.showDesktop"
       :data-hide-mobile="!this.internal_config.showMobile"
       :config-properties="JSON.stringify(internal_config)" :style="style()" v-on:click="focusElement($event)">


    <iframe v-if="internal_config.secret" width="100%" :height="getHeight" :src="getSecret" frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" controls="1" countdown="1"
            allowfullscreen></iframe>

  </div>
</template>

<script>
import elementData from './Model';
import _ from 'lodash'

export default {
	name: 'VideoComponent',
	props: ['config', 'id'],
	data() {
		return elementData.data();
	},
	computed: {
		getSecret() {
			let url = `https://videomng.builderall.com/embed/${this.internal_config.secret}?allowpause=1`

			if (this.internal_config.autoplay) {
				url = url + '&autoplay=1'
			}

			if (this.internal_config.countdown) {
				url = url + '&countdown=1'
			}

			if (this.internal_config.loop) {
				url = url + '&loop=1'
			}

			if (this.internal_config.controls) {
				url = url + '&controls=1'
			}

			return url;
		},
		getHeight() {
			let height = document.querySelector('[data-device]').getAttribute('data-device') === 'desktop' ? this.internal_config.height : this.internal_config.minHeight;
			if (height === '0px') {
				height = '100%'
			}
			return height;
		}
	},
	methods: {
		build(config) {
			this.internal_config = _.merge(this.internal_config, config);
		},
		animation() {
			return true;
		},
		style() {
			return `width: ${this.internal_config.width};
               height:  ${this.internal_config.height};
               z-index: ${this.internal_config.zIndex};
               margin: 0 auto;
               padding-top: ${this.internal_config.paddingTop};
               padding-left: ${this.internal_config.paddingLeft};
               padding-right: ${this.internal_config.paddingRight};
               padding-bottom: ${this.internal_config.paddingBottom};
               left:  ${this.internal_config.left};
               top: ${this.internal_config.top}`;
		}
	},
	mounted() {
		this.build(this.$props.config);
		this.$root.$on('setConfigElement', (id, obj) => {
			if (this.id === id) {
				this.config = obj;
				this.internal_config = obj;
			}
		});

		this.$root.$emit('applyAnimation', this, 'sitebuilder-element-container');
	},
};
</script>

<style>


</style>
