<template>
  <div
    :id="id"
    :class="`sitebuilder-element-container ${internal_config.fullWidth ? 'full-width-element' : ''}  ${internal_config.fullHeight ? 'full-height-calendar' : ''}`"
    element="QuizComponent"
    :data-hide-desktop="!internal_config.showDesktop"
    :data-hide-mobile="!internal_config.showMobile"
    :config-properties="JSON.stringify(internal_config)"
    :style="style()">
    <div
      v-if="internal_config.quiz.id"
      class="embed-responsive embed-responsive-16by9"
      :style="{ width : width,
                height : internal_config.fullHeight ? '100%' : height,
                minHeight : minHeight }"
    >
      <iframe
        :width="internal_config.width"
        :height="internal_config.height"
        :src="`${getUrl}/view-by/cheetah/${internal_config.quiz.id}`"
        :style="{
          borderRadius: radius,
          border : border
        }"
        frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
        class="embed-responsive-item"
      />
    </div>
    <div class="quiz-is-empty" v-else>
        <h6 class="text-center">{{ $t('ct-select-one-quiz') }}</h6>
    </div>
  </div>
</template>
<script>

import Model from './Model';
import {mixinQuiz} from '../../../js/mixins/quiz';

export default {
  name: 'QuizComponent',
  props: ['config', 'device', 'id', 'preview'],
  mixins: [mixinQuiz],
  data() {
    return Model.data();
  },
  computed: {
    width() {
      return this.internal_config.width;
    },
    minHeight() {
      return this.internal_config.minHeight;
    },
    height() {
      return this.internal_config.height;
    },
    border() {
      return this.internal_config.border;
    },
    radius() {
      return this.internal_config.borderRadius;
    },
    getUrl() {
      const host = window.location.host;
      return this.getQuizUrl(host);
    }
  },

  methods: {
    build(options) {
      this.internal_config.quiz = options;

      // if(this.internal_config.fullHeight && this.internal_config.fullWidth) {
      //   this.internal_config.left = '0px';
      //   this.internal_config.height = '720px';
      //   this.internal_config.width = '1024px';
      // }
    },
    animation() {
      return true;
    },
    style() {
      let style = `left:  ${this.internal_config.left};
                     padding-top: ${this.internal_config.paddingTop};
                     padding-left: ${this.internal_config.paddingLeft};
                     z-index: ${this.internal_config.zIndex};max-width: ${this.internal_config.maxWidth};margin: 0 auto;
                     padding-right: ${this.internal_config.paddingRight};
                     padding-bottom: ${this.internal_config.paddingBottom};
                     top: ${this.internal_config.top}`;
      return style;
    },
  },
  created() {
    this.internal_config = Object.assign({}, this.internal_config, window.mergeRecursive(this.props, this.$props.config));
  },
  mounted() {
    this.internal_config = window.mergeRecursive(this.props, this.$props.config);
    var classList = 'sitebuilder-element-container';

    if(this.internal_config.fullWidth) {
       classList+= ' full-width-element';
    }

    if(this.internal_config.fullHeight) {
      classList+= ' full-height-quiz';
    }

    this.$root.$emit('applyAnimation', this, classList);
  }
};

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

[data-device="desktop"] {
  .full-height-quiz .vdr {
    height: 720px !important;
  }

  .embed-responsive {
    min-height: unset !important;
  }
}

.quiz-is-empty {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #FFF;
}

</style>
