import axios from 'axios'

function getApiUrl () {
  const prefix   = '/api/';
  const hostName = location.hostname;

  const isDev = process.env.NODE_ENV === 'development' ||
    hostName === 'website.localhost'                   ||
    hostName === '127.0.0.1'

  if (isDev) {
    return `http://127.0.0.1:8000${prefix}`;
  }

  switch (true) {
    case hostName.includes('sitebuilder-publication.kub.app.builderall.io'):
      return `https://api-sitebuilder.kub.app.builderall.io${prefix}`;
    case hostName.includes('sitebuilder-publication.kub.app.builderall.info'):
      return `https://api-sitebuilder.kub.app.builderall.info${prefix}`;
  }
}

const url = getApiUrl();

const http = axios.create({
  baseURL: url,
  headers: {
    'Accept': 'application/json',
    'Content': 'application/json'
  }
});

export default http
