import axios from 'axios';
import {split} from "vue-notification/src/util";

function handlerUrls(){
    const host = window.location.host;

    const hostMap = {        
        '.localhost:': {
            blogUrl: 'http://blog.localhost',
            apiUrl: 'http://api.localhost'
        },

        '.localhost': {
            blogUrl: 'http://blog.localhost',
            apiUrl: 'http://api.localhost+'
        },

        '.sitebuilder-publication.kub.app.builderall.io': {
            blogUrl: 'https://blog.kub.app.builderall.io',
            apiUrl: 'https://api-sitebuilder.kub.app.builderall.io'

        },

        '.sitebuilder-publication.kub.app.builderall.info': {
           blogUrl: 'https://blog.kub.app.builderall.info',
           apiUrl: 'https://api-sitebuilder.kub.app.builderall.info'
        },
        
        'default': {
            blogUrl: 'https://blog.builderall.com',
            apiUrl: 'https://cheetah-api.builderall.com'
        }
    }

    const foundKey   = Object.keys(hostMap).find(key => host.includes(key));
    const hostConfig = hostMap[foundKey] || hostMap['default'];

    return hostConfig;
}


function makeUrlPost() {
    let url = '';

    if (window.location.host.indexOf('localhost:') !== -1) {
        url = `https://gesundundfitbleiben.com/cheetah/blog/get-new?post=viren-kann-man-nicht-bekampfen-man-muss-mit-ihnen-leben`;
    }else{
        url = `${url}/cheetah/blog/get-new?post=${window.location.pathname.replace('/', '')}`
    }

    return url;
}

function makeUrlCapture() {
    let url = '';

    if (window.location.host.indexOf('localhost:') !== -1) {
        url = `http://publication.localhost/cheetah/blog/get-capture-config`;
    }else{
        url = `${url}/cheetah/blog/get-capture-config`
    }

    return url;
}

function makeGeneralUrls(path = '') {
    const url = handlerUrls();

    return `${url.blogUrl}${path}`;
}

function makeGeneralUrlsApi(path = '') {
    const url = handlerUrls();

    console.log('URL API', `${url.apiUrl}${path}`);

    return `${url.apiUrl}${path}`;
}
function getWebsiteIDParam() {
    let url = '';

    if (window.location.host.indexOf('localhost:') !== -1 || window.location.host.indexOf('localhost') !== -1) {
        url = `website_id=MQ==`;
    }else{
        url = `website_id=${OPTIONS_INIT_SITE.options.w_code}`;
    }

    return url;
}

export const getActualNew = () => {
    return axios.get(makeUrlPost());
};

export const storePermission = (url) => {
    return axios.get(makeGeneralUrlsApi(url));

}

export const getCaptureConfig = () => {
    return axios.get(makeUrlCapture());
};

export const getNewByID = (id) => {
    return axios.get(makeGeneralUrls(`/news/get-by-id/${id}` + '?' + getWebsiteIDParam()));
};

export const getFilteredNews = (config) => {
    let path = `/news/get-filtered-news?category=${config.filters.category}&author=${config.filters.author}&featured=${config.filters.featured}&most_accessed=${config.filters.mostAccessed}&amount=${config.filters.amount}&type=${config.type}`;

    return axios.get(makeGeneralUrls(path + '&' + getWebsiteIDParam()));
};


export const getKey = () => {
    let path = `/get-key?${getWebsiteIDParam()}`;
    return axios.get(makeGeneralUrls(path));
};

export const getFeedNews = (config, pagination, key = false) => {
    if(config.filters.category.indexOf('/') !== -1){
        config.filters.category = config.filters.category.split('/')[0]
    }
    if(config.filters.author.indexOf('/') !== -1){
        config.filters.author = config.filters.author.split('/')[0]
    }

    let path = `/get-news?category=${config.filters.category}&author=${config.filters.author}&featured=${config.filters.featured}&most_accessed=${config.filters.mostAccessed}&amount=${config.filters.amount}&page=${pagination.current_page}`;


    if(config.filters.search) {
        path = `${path}&search=${config.filters.search}`
    }


    if(key){
        path = `${path}&utc_time=${key}`
    }

    return axios.get(makeGeneralUrls(path + '&' + getWebsiteIDParam()));
};



