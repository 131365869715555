<template>
    <div
      :class="'sitebuilder-element-panel'  + hideControl()" :data-fixed="fixedBanner()"
      :data-fixed-mobile="fixedBannerMobile()"
      :data-tag="dataTag()"
      :data-footer-fixed="fixedFooterBanner()"
      :data-footer-fixed-mobile="fixedFooterBannerMobile()"
      ref="panel"
  >
    <div
        ref="elem"
        element="PanelComponent"
        class="panel panel-primary draggable container-fluid"
        :id="this.internal_config.id"
        :data-hide-desktop="!this.internal_config.showDesktop"
        :data-hide-mobile="!this.internal_config.showMobile"
        :data-anchor-name="(this.internal_config && typeof this.internal_config.anchorName !== 'undefined' && this.internal_config.anchorName !== '')? this.internal_config.anchorName : ''"
        :style="handlerCssProps"
    >
            <div class="panel-helper-ios" v-if="($isDeviceIOS() && !internal_config.parallaxActive && backgroundAttachment === 'fixed') || (device !== 'desktop'  && backgroundAttachment === 'fixed')">
                <div class="panel-helper-ios-image" :style="{
                    'border-radius': radius,
                    background: backgroundColor,
                    backgroundImage: backgroundImage,
                    backgroundSize: backgroundSize,
                    backgroundPosition: backgroundPosition,
                    backgroundOrigin: backgroundOrigin,
                    backgroundRepeat: backgroundRepeat,
                    backgroundAttachment: backgroundAttachment,
                    border : border,
                    'box-shadow': boxShadow,
                }"></div>
            </div>
            <div class="panel-video-place"
                 v-show="internal_config.video != '' && internal_config.videoInternal == false">
                <video class="panel-video" playsinline :autoplay="internal_config.autoplay" :muted="internal_config.muted"
                       :loop="internal_config.loop" id="myVideo">
                    <source :src="srcVideo" :type="typeVideo">
                </video>
            </div>
            <div class="panel-body container" v-bind:style="{
             background: internalBackgroundColor,
            backgroundImage: internalBackgroundImage,
            backgroundSize: internalBackgroundSize,
            backgroundPosition: internalBackgroundPosition,
            backgroundOrigin: internalBackgroundOrigin,
            backgroundRepeat: internalBackgroundRepeat,
			backgroundAttachment: internalBackgroundAttachment,
            minHeight : minHeight,
            }">
                <div class="panel-video-place"
                     v-show="internal_config.video != '' && internal_config.videoInternal == true">
                    <video class="panel-video" playsinline :autoplay="internal_config.autoplay" :muted="internal_config.muted"
                           :loop="internal_config.loop" id="myVideoInternal">
                        <source :src="srcVideo" :type="typeVideo">
                    </video>
                </div>

                 <div v-if="props.columns && device !== 'desktop'" class="row">
                     <component :class="`col-${12/props.columns_count}`" :style="`width: ${ 100 / props.columns_count }% !important`" v-for="(element,index) in elements" :is="element.name" :config="element.config"
                          :childs="element.childs" :key="index"
                          :device="device"
                          :id="element.id" :height_start="index" :preview="preview"
                          @setCallbackChangeDevice="setCallbackChangeDevice"></component>
                 </div>
                <component v-else v-for="(element,index) in elements" :is="element.name" :config="element.config"
                           :childs="element.childs" :key="index"
                           :device="device"
                           :is_popup="is_popup"
                           :id="element.id" :height_start="index" :preview="preview"></component>
            </div>
            <div class="arrow-panel-down"
                 v-show="internal_config.arrowActive"
                 v-bind:style="{
                         width: widthArrow,
                         left: leftArrow,
                         'border-left': transparentArrowSide,
                         'border-right': transparentArrowSide,
                         'border-top': colorArrowSide}">
            </div>
        </div>
        <separator-panel
            :data-hide-desktop="!this.internal_config.showDesktop"
            :data-hide-mobile="!this.internal_config.showMobile"
            ref="separatorData"
        ></separator-panel>
        <div
            :data-hide-desktop="!this.internal_config.showDesktop"
             :data-hide-mobile="!this.internal_config.showMobile"
             v-html="this.internal_config.separatorHtml"
        ></div>
    </div>
</template>
<script>

import Model from './Model';
import SeparatorPanel from "./Separator";
import axios from 'axios';

export default {
        name: 'PanelElement',
        components: {
            SeparatorPanel,
        },
        props: ['config', 'childs', 'height_start', 'device', 'id', 'preview', 'is_popup', 'is_footer'],
        data() {
            return Model.data()
        },
        computed: {
            handlerCssProps() {
                const cssProps = {
                    'border-radius': this.radius,
                    background: this.backgroundColor,
                    backgroundImage: this.backgroundImage,
                    backgroundSize: this.backgroundSize,
                    backgroundPosition: this.backgroundPosition,
                    backgroundOrigin: this.backgroundOrigin,
                    backgroundRepeat: this.backgroundRepeat,
                    backgroundAttachment: this.backgroundAttachment,
                    height: this.height,
                    minHeight: this.minHeight,
                    borderWidth: this.borderWidth,
                    borderStyle: this.borderStyle(),
                    borderColor: this.borderColor(),
                    'box-shadow': this.boxShadow,
                }

                if (this.backgroundColor && this.backgroundColor.includes("linear-gradient")) {
                    delete cssProps.backgroundImage;
                }

                return cssProps;
            },
            widthArrow() {
                if (this.device == 'desktop') {
                    return this.internal_config.arrowWidth + '%';
                } else {
                    return this.internal_config.arrowWidthMobile + '%';
                }
            },
            arrowWidth() {
                if (this.device === 'desktop') {
                    return parseInt(this.internal_config.arrowPanelWidth) / 100 * (this.internal_config.arrowWidth / 2);
                }
                return parseInt(this.internal_config.arrowPanelWidth) / 100 * (this.internal_config.arrowWidthMobile / 2);
            },
            leftArrow() {
                if (this.device === 'desktop') {
                    return 'calc(50% - ' + this.arrowWidth + 'px)';
                }
                return 'calc(50% - ' + this.arrowWidth + 'px)';
            },
            transparentArrowSide() {

                if (this.device === 'desktop') {
                    return this.arrowWidth + 'px solid transparent';
                }
                return this.arrowWidth + 'px solid transparent';
            },
            colorArrowSide() {
                return this.internal_config.arrowHeight + 'px solid ' + this.internal_config.arrowColor;
            },

            minHeight() {
                return this.internal_config.minHeight;
            },
            border() {
                if (typeof this.internal_config.border !== 'undefined') {
                    let splitedBorder = this.internal_config.border.split(' ');

                    if (typeof splitedBorder[2] !== 'undefined' && this.$globalStoreHelpers.isValidHex(splitedBorder[2])) {
                        splitedBorder[2] = this.$globalStoreHelpers.hexToRGBA(splitedBorder[2]);
                        this.internal_config.border = splitedBorder.join(' ');
                    }
                }
                return this.internal_config.border;
            },
            borderWidth() {
              return this.internal_config.borderWidth;
            },
            height() {
                return this.internal_config.height;
            },
            boxShadow() {
                return this.internal_config.boxShadow;
            },
            radius() {
                return this.internal_config.borderRadius;
            },
            backgroundColor() {

                if (this.$globalStoreHelpers.isValidHex(this.internal_config.backgroundColor)) {
                    this.internal_config.backgroundColor = this.$globalStoreHelpers.hexToRGBA(this.internal_config.backgroundColor);
                }

                return this.internal_config.backgroundColor;
            },
            backgroundImage() {
                if (this.internal_config.backgroundImage && this.internal_config.backgroundImage !== 'none') {
                	if(this.internal_config.backgroundImage !== null)
                        this.internal_config.backgroundImage = this.internal_config.backgroundImage.replace('omb11.com', 'storage.builderall.com');
                    return 'url("' + this.internal_config.backgroundImage + '")';
                }
                return '';
            },
            backgroundPosition() {
                if (this.internal_config.backgroundImage && this.internal_config.backgroundImage !== 'none') {
                    return this.internal_config.backgroundPosition
                }
                return '';
            },
            backgroundOrigin() {
                if (this.internal_config.backgroundImage && this.internal_config.backgroundImage !== 'none') {
                    return this.internal_config.backgroundOrigin
                }
                return '';
            },
            backgroundRepeat() {
                if (this.internal_config.backgroundImage && this.internal_config.backgroundImage !== 'none') {
                    return this.internal_config.backgroundRepeat
                }
                return '';
            },
            backgroundSize() {
                if (this.internal_config.backgroundImage && this.internal_config.backgroundImage !== 'none') {
                    return this.internal_config.backgroundSize
                }
                return '';
            },
            backgroundAttachment() {
                if (this.internal_config.backgroundImage && this.internal_config.backgroundImage !== 'none') {
                    return this.internal_config.backgroundAttachment
                }
                return '';
            },
            internalBackgroundColor() {

                if (this.$globalStoreHelpers.isValidHex(this.internal_config.internalBackgroundColor)) {
                    this.internal_config.internalBackgroundColor = this.$globalStoreHelpers.hexToRGBA(this.internal_config.internalBackgroundColor);
                }


                return this.internal_config.internalBackgroundColor;
            },
            internalBackgroundImage() {
                if (this.internal_config.internalBackgroundImage && this.internal_config.internalBackgroundImage !== 'none') {
                    this.internal_config.internalBackgroundImage = this.internal_config.internalBackgroundImage.replace('omb11.com', 'storage.builderall.com');
                    return 'url("' + this.internal_config.internalBackgroundImage + '")';
                }
                return '';
            },
            internalBackgroundPosition() {
                if (this.internal_config.internalBackgroundImage && this.internal_config.internalBackgroundImage !== 'none') {
                    return this.internal_config.internalBackgroundPosition
                }
                return '';
            },
            internalBackgroundOrigin() {
                if (this.internal_config.internalBackgroundImage && this.internal_config.internalBackgroundImage !== 'none') {
                    return this.internal_config.internalBackgroundOrigin
                }
                return '';
            },
            internalBackgroundRepeat() {
                if (this.internal_config.internalBackgroundImage && this.internal_config.internalBackgroundImage !== 'none') {
                    return this.internal_config.internalBackgroundRepeat
                }
                return '';
            },
            internalBackgroundSize() {
                if (this.internal_config.internalBackgroundImage && this.internal_config.internalBackgroundImage !== 'none') {
                    return this.internal_config.internalBackgroundSize
                }
                return '';
            },
            internalBackgroundAttachment() {
                if (this.internal_config.internalBackgroundImage && this.internal_config.internalBackgroundImage !== 'none') {
                    return this.internal_config.internalBackgroundAttachment
                }
                return '';
            },
            typeVideo() {
                try {
                    if (typeof this.internal_config.video != 'undefined' && this.internal_config.video != '') {
                        return 'video/' + this.internal_config.video.split('.').pop();
                    }
                } catch (e) {
                    console.log(e);
                    return '';
                }

                return '';
            },
            srcVideo() {
                try {
                    if (typeof this.internal_config.video != 'undefined' && this.internal_config.video !== '') {
                        return this.internal_config.video;
                    }
                } catch (e) {
                    console.log(e);
                    return '';
                }

                return '';
            },
        },
        methods: {
            borderColor() {
              let [borderWidth, borderStyle, borderColor] = this.internal_config.border.split(' ');
              return borderColor;
            },
            borderStyle() {
              let [borderWidth, borderStyle, borderColor] = this.internal_config.border.split(' ');
              return borderStyle;
            },
            changeHeight(size, positive, id, top, left, width, isBlogElement = false) {


                if (this.device == 'desktop') {
                    if (positive)
                        this.internal_config.height = parseInt(this.$el.getBoundingClientRect().height) + size + 'px';
                    else
                        this.internal_config.height = parseInt(this.$el.getBoundingClientRect().height) - parseInt(size) + 'px';

                    for (var i in this.elements) {


                        if (this.elements[i].id != id) {

                            let thisCanBeChanged = document.getElementById(this.elements[i].id);


                            /**
                             * formulario de contato tem id dinamico
                             */
                            if(this.elements[i].name === 'SimpleFormComponent') {
                                thisCanBeChanged = document.querySelectorAll(`[data-id="${this.elements[i].id}"]`)[0]
                            }




							if (
								typeof $(thisCanBeChanged).offset() != 'undefined' &&
								parseInt($(thisCanBeChanged).offset().left) >= left - 5  &&
								parseInt($(thisCanBeChanged).position().top) >= top - 5 &&
								parseInt($(thisCanBeChanged).offset().left) <= (left + width)
							) {


                                if(!isBlogElement){
                                if (positive)
                                    thisCanBeChanged.style.top = parseInt($(thisCanBeChanged).position().top) + size + 'px';
                                else
                                    thisCanBeChanged.style.top = parseInt($(thisCanBeChanged).position().top) - size + 'px';
                                }

                            }
                        }
                    }
                }

            },

            panelDividerEffect() {
                this.internal_config.separatorHtml = '';
                if (this.internal_config.separatorActive) {
                    let separatorColors = this.internal_config.separatorColors;

                    if (this.$globalStoreHelpers.isValidHex(separatorColors['first'])) {
                        separatorColors['first'] = this.$globalStoreHelpers.hexToRGBA(separatorColors['first']);
                    }
                    if (this.$globalStoreHelpers.isValidHex(separatorColors['second'])) {
                        separatorColors['second'] = this.$globalStoreHelpers.hexToRGBA(separatorColors['second']);
                    }
                    if (this.$globalStoreHelpers.isValidHex(separatorColors['third'])) {
                        separatorColors['third'] = this.$globalStoreHelpers.hexToRGBA(separatorColors['third']);
                    }
                    if (this.$globalStoreHelpers.isValidHex(separatorColors['fourth'])) {
                        separatorColors['fourth'] = this.$globalStoreHelpers.hexToRGBA(separatorColors['fourth']);
                    }

					if (this.internal_config.separatorEffect === 'waves-floating')
						this.internal_config.separatorHtml = this.$refs.separatorData.wavesSeparator(
							separatorColors['first'],
							separatorColors['second'],
							separatorColors['third'],
							separatorColors['fourth'],
							separatorColors['custom'],
							separatorColors['value1'],
							separatorColors['value2'],
							separatorColors['value3'],
							separatorColors['value4']
						);
					if (this.internal_config.separatorEffect === 'drip-up')
						this.internal_config.separatorHtml = this.$refs.separatorData.dripColors(
							separatorColors['first'],
							separatorColors['second'],
							separatorColors['third'],
							'up',
							separatorColors['custom'],
							separatorColors['value1'],
							separatorColors['value2'],
							separatorColors['value3']
						);
					if (this.internal_config.separatorEffect === 'drip-down')
						this.internal_config.separatorHtml = this.$refs.separatorData.dripColors(
							separatorColors['first'],
							separatorColors['second'],
							separatorColors['third'],
							'down',
							separatorColors['custom'],
							separatorColors['value1'],
							separatorColors['value2'],
							separatorColors['value3']
						);
					if (this.internal_config.separatorEffect === 'mid-arrow')
						this.internal_config.separatorHtml = this.$refs.separatorData.midArrow(
							separatorColors['first'],
							separatorColors['second'],
							separatorColors['third'],
							separatorColors['custom'],
							separatorColors['value1'],
							separatorColors['value2'],
							separatorColors['value3']
						);
					if (this.internal_config.separatorEffect === 'waves-fixed')
						this.internal_config.separatorHtml = this.$refs.separatorData.wavesFixed(
							separatorColors['first'],
							separatorColors['second'],
							separatorColors['third'],
							separatorColors['custom'],
							separatorColors['value1'],
							separatorColors['value2'],
							separatorColors['value3']
						);
					if (this.internal_config.separatorEffect === 'polygon')
						this.internal_config.separatorHtml = this.$refs.separatorData.polygonCraqued(
							separatorColors['first'],
							separatorColors['second'],
							separatorColors['third'],
							separatorColors['custom'],
							separatorColors['value1'],
							separatorColors['value2'],
							separatorColors['value3']
						);
					if (this.internal_config.separatorEffect === 'polygon-up')
						this.internal_config.separatorHtml = this.$refs.separatorData.polygonUp(
							separatorColors['first'],
							separatorColors['second'],
							separatorColors['third'],
							separatorColors['custom'],
							separatorColors['value1'],
							separatorColors['value2'],
							separatorColors['value3']
						);
					if (this.internal_config.separatorEffect === 'banner-lateral')
						this.internal_config.separatorHtml = this.$refs.separatorData.bannerLateral(
							separatorColors['first'],
							separatorColors['second'],
							separatorColors['third'],
							separatorColors['custom'],
							separatorColors['value1'],
							separatorColors['value2'],
							separatorColors['value3']
						);
					if (this.internal_config.separatorEffect === 'drops')
						this.internal_config.separatorHtml = this.$refs.separatorData.drops(
							separatorColors['first'],
							separatorColors['second'],
							separatorColors['custom'],
							separatorColors['value1'],
							separatorColors['value2']
						);
					if (this.internal_config.separatorEffect === 'waves-lateral')
						this.internal_config.separatorHtml = this.$refs.separatorData.wavesLateral(
							separatorColors['first'],
							separatorColors['second'],
							separatorColors['third'],
							separatorColors['custom'],
							separatorColors['value1'],
							separatorColors['value2'],
							separatorColors['value3']
						);
					if (this.internal_config.separatorEffect === 'zigzag')
						this.internal_config.separatorHtml = this.$refs.separatorData.zigzag(
							separatorColors['first'],
							separatorColors['second'],
							separatorColors['third'],
							separatorColors['custom'],
							separatorColors['value1'],
							separatorColors['value2'],
							separatorColors['value3']
						);
					if (this.internal_config.separatorEffect === 'big-arrow')
						this.internal_config.separatorHtml = this.$refs.separatorData.bigArrow(
							separatorColors['first'],
							separatorColors['second'],
							separatorColors['third'],
							separatorColors['custom'],
							separatorColors['value1'],
							separatorColors['value2'],
							separatorColors['value3']
						);
					if (this.internal_config.separatorEffect === 'clouds')
						this.internal_config.separatorHtml = this.$refs.separatorData.cloudsEffect(
							separatorColors['first'],
							separatorColors['custom'],
							separatorColors['value1']
						);
					if (this.internal_config.separatorEffect === 'curve')
						this.internal_config.separatorHtml = this.$refs.separatorData.curveEffect(
							separatorColors['first'],
							separatorColors['second'],
							separatorColors['third'],
							separatorColors['custom'],
							separatorColors['value1'],
							separatorColors['value2'],
							separatorColors['value3']
						);
					if (this.internal_config.separatorEffect === 'arrow-reverse')
						this.internal_config.separatorHtml = this.$refs.separatorData.arrowReverse(
							separatorColors['first'],
							separatorColors['second'],
							separatorColors['third'],
							separatorColors['custom'],
							separatorColors['value1'],
							separatorColors['value2'],
							separatorColors['value3']
						);
					if (this.internal_config.separatorEffect === 'pixels')
						this.internal_config.separatorHtml = this.$refs.separatorData.pixels(
							separatorColors['first'],
							separatorColors['second'],
							separatorColors['third'],
							separatorColors['custom'],
							separatorColors['value1'],
							separatorColors['value2'],
							separatorColors['value3']
						);
					if (this.internal_config.separatorEffect === 'big-round')
						this.internal_config.separatorHtml = this.$refs.separatorData.bigRound(
							separatorColors['first'],
							separatorColors['second'],
							separatorColors['custom'],
							separatorColors['value1'],
							separatorColors['value2']
						);
					if (this.internal_config.separatorEffect === 'breaker')
						this.internal_config.separatorHtml = this.$refs.separatorData.breaker(
							separatorColors['first'],
							separatorColors['second'],
							separatorColors['third'],
							separatorColors['custom'],
							separatorColors['value1'],
							separatorColors['value2'],
							separatorColors['value3']
						);
                }
            },

            dataTag() {
                if (this.internal_config.tag) {
                    return this.internal_config.tag;
                }
                return '';
            },
            fixedFooterBanner() {
              if (this.internal_config.fixed && this.is_footer) {
                return true;
              }
              return false;
            },
            fixedFooterBannerMobile() {
              if (this.internal_config.fixedMobile && this.is_footer) {
                this.$root.$emit('setPanelScrollFixedZIndex', this.$el);
                return true;
              }
              return false;
            },
            fixedBanner() {

                if (this.internal_config.fixed) {
                    var panels = document.querySelectorAll('.sitebuilder-element-panel:not(.hide-on-desktop)');

                    let firstEl = Array.from(panels)
                        .find(node => node.querySelector('div.panel:not([data-hide-desktop=true])'));

                    var isFreeWebsite = document.body.classList.contains('free-website');
                    var heightFreeBanner = 50;

                    if (this.$el === firstEl)
                        this.$root.$emit('setFixedStartPanel', (isFreeWebsite) ? parseInt(this.internal_config.height) + heightFreeBanner + 'px' : this.internal_config.height);

                    return this.$el === firstEl;
                }

                return false;
            },
            fixedBannerMobile() {

                if (this.internal_config.fixedMobile) {
                  var panels = document.querySelectorAll('.sitebuilder-element-panel');

                  let firstEl = Array.from(panels)
                      .find(node => node && node.querySelector('div.panel:not([data-hide-mobile=true])'));

                  if (this.$el === firstEl) {
                    var heightReal = $(firstEl).height();

                    this.$root.$emit('setFixedStartPanel', (heightReal > parseFloat(this.internal_config.minHeight)) ? heightReal : this.internal_config.minHeight, 'mobile');
                  }

                  return this.$el === firstEl;
                }

                return false;
            },

            hideControl: function () {
                var classAdd = '';
                if (!this.internal_config.showMobile) {
                    classAdd = classAdd + ' hide-on-mobile'
                }

                if (!this.internal_config.showDesktop) {
                    classAdd = classAdd + ' hide-on-desktop'
                }


                if(typeof this.internal_config.hidePanel !== 'undefined' && this.internal_config.hidePanel){
                    classAdd = classAdd + ' hiden-panel'
                }

                if(this.config.tag == 'painelhide'){
                    console.log('caiu no hide')
                    classAdd = classAdd + ' hiden-panel'
                }


                return classAdd;
            },

            duplicatePanel() {
                this.$root.$emit('duplicatePanel', this.id);
            },
            togglePanel() {
                this.$root.$emit('toggleElement', this);
            },
            showResources() {
            },
            //change order on html
            changeOrderPanel(elem, idx) {
                let id = elem.$el.id,
                    sibling = idx === 1 ? document.getElementById(id).nextSibling : document.getElementById(id).previousSibling;

                if (sibling === null) {
                    return true;
                }

                let vm = this;
                let elements = this.$parent.changeOrderElements(vm.id, id, sibling.id, idx);

                vm.elements = {};
                this.$nextTick(function () {
                    vm.elements = elements.childs.sort((a, b) => (a.config.order_mobile > b.config.order_mobile) ? 1 : ((b.config.order_mobile > a.config.order_mobile) ? -1 : 0));
                })
            },
            controlDisplayPanelMboss(){
                let data = JSON.parse(localStorage.getItem('mboss-data'));


                console.log(this.config.enabled_tags);
                this.config.enabled_tags.forEach( (tag) => {
                    if(data && typeof data[tag.column] !== 'undefined' ){
                        if(tag.operator === 'equal'){
                            if(data[tag.column] == tag.value ){
                                this.$el.style.display = 'block'
                            }
                        }
                        if(tag.operator === 'bigger'){
                            if(data[tag.column] > tag.value ){
                                this.$el.style.display = 'block'
                            }
                        }
                        if(tag.operator === 'smaller'){
                            if(data[tag.column] < tag.value ){
                                this.$el.style.display = 'block'
                            }
                        }

                        }
                    } )

            }
        },
        created() {

          if (typeof this.config.borderWidth === 'undefined' && typeof this.$props.config.border !== 'undefined') {
            let [borderWidth] = this.$props.config.border.split(' ');
            this.$props.config.borderWidth = `${borderWidth} ${borderWidth} ${borderWidth} ${borderWidth}`;
          }
        },
        mounted: function () {
            let device, childs, id, config;
            ({device, childs, id, config} = this.$props);

            try{
                if(config.enable_tags) {
                    let website_id = atob(OPTIONS_INIT_SITE.options.w_code);
                    let email = localStorage.getItem('leadEmail')

                    this.$el.style.display = 'none'
                    if(localStorage.getItem('mboss-data') == null ){
                        axios.get(`/cheetah/search-mboss?website_id=${website_id}&email=${email}`)
                        .then( res => {
                            localStorage.setItem('mboss-data', JSON.stringify(res.data))
                            this.controlDisplayPanelMboss();

                        } )
                    }else{
                        this.controlDisplayPanelMboss();
                    }
                }
            }catch(e){
                this.$el.style.display = 'block'
                console.log(e)
            }


            try{
                if(config.tag === "painelhide"){
                console.log(this.$el.style.display = 'none');
                }
            }catch(e){
                console.log(e)
            }


            this.internal_config = config;
            this.device = device;
            this.elements = childs.sort((a, b) => (a.config.order_mobile > b.config.order_mobile) ? 1 : ((b.config.order_mobile > a.config.order_mobile) ? -1 : 0));
            if (typeof id !== 'undefined') {
                this.id = id;
            }

            this.internal_config = window.mergeRecursive(this.props, this.$props.config);

            /*if (typeof this.$props.config.anchorName !== 'undefined' && this.$props.config.anchorName !== '') {
                this.internal_config.id = this.$props.config.anchorName;
            }*/

            let vm = this;
            setTimeout(function () {

                if (vm.internal_config.parallaxActive == true) {
                    $(vm.$refs.elem).jarallax({
                        type: vm.internal_config.parallaxEffect,
                        speed: vm.internal_config.parallaxSpeed / 10,
                        imgSize: vm.internal_config.backgroundSize,
                        imgPosition: vm.internal_config.backgroundPosition,
                        imgRepeat: vm.internal_config.backgroundRepeat,
                    });
                }

            }, 1000);

            if(vm.internal_config.infiniteSliderAnimation){
                setTimeout(function () {
                let panel = document.getElementById(vm.internal_config.id)
                let container = panel.querySelector('.panel-body')
                container.classList.add("slide-track")
                },10)
            }

            //hidePanel
            if(vm.internal_config.hidePanel && vm.internal_config.typeHidePanel === 'time'){
                setTimeout(function () {
                    vm.internal_config.hidePanel = false
                    let addFade = document.getElementById(vm.internal_config.id)
                    $(addFade).addClass('fadein');
                    // $(addFade).removeClass('fadein');
                }, vm.internal_config.timeHidePanel * (1000 * (vm.internal_config.unitsHidePanel === 'seconds' ? 1 : 60) ));
            } else {
                //if action type
            }


            this.panelDividerEffect();

            this.$nextTick(() => {
                if (this.device === 'mobile') {
                    this.fixedBannerMobile();
                }

                this.$nextTick(() => {
                    let arrowPanelWidth = parseInt($('.sitebuilder-element-panel').width());
                    this.internal_config.arrowPanelWidth = arrowPanelWidth;
                });
            });
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    /*
    fadeIn hide panel
     */
    .fadein{
        animation: fadein 2s; /* Padrão */
        -webkit-animation: fadein 2s; /* Webkit */
        -moz-animation: fadein 2s; /* Firefox */
        -ms-animation: fadein 2s; /* IE */
    }

    @keyframes scroll {
	  0% {
        transform: translate3d(110%, 0, 0);
    }
      100% {
        transform: translate3d(-130%, 0, 0); /* The image width */
    }
    }

    .slide-track {
		animation: scroll 15s linear infinite;
		display: flex;
		width: 100%;
	}


    @keyframes fadein {
        from {
            opacity:0;
        }
        to {
            opacity:1;
        }
    }
    @-moz-keyframes fadein { /* Firefox */
        from {
            opacity:0;
        }
        to {
            opacity:1;
        }
    }
    @-webkit-keyframes fadein { /* Safari and Chrome */
        from {
            opacity:0;
        }
        to {
            opacity:1;
        }
    }
    @-o-keyframes fadein { /* Opera */
        from {
            opacity:0;
        }
        to {
            opacity: 1;
        }
    }

    .panel-video,
    .panel-video-place {
        position: absolute;
        min-width: 100%;
        min-height: 100%;
        overflow: hidden;
        left: 0;
    }

    .arrow-panel-down {
        position: absolute;
        z-index: 10;
    }

    .panel {
        width: 100%;
    }

    .sitebuilder-element-panel,
    .panel,
    .panel-body {
        position: relative;
    }

    .resizable {
        /* overflow: hidden; */
        resize: both
    }

    .draggable {
        /* position: relative; */
        /* z-index: 100 */
    }

    .draggable-handler {
        cursor: pointer
    }

    .dragging {
        cursor: move;
        /* z-index: 200 !important */
    }

    .panel-body {
        height: 100%;
    }

    .v--modal-overlay[data-modal="my_modal_name"] {
        background: transparent;
    }

    .container {
        /*border: 1px dotted gray;*/
    }

    .container-fluid {
        /*border: 1px dotted gray;*/
    }

    .panel-helper-ios{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: none;
        clip: rect(0, auto, auto, 0);
    }

    [data-device="mobile"] .panel-helper-ios, [data-device="tablet"] .panel-helper-ios {
        display: block;
    }

    .panel-helper-ios-image {
        position: fixed;
        display: block;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transform: translateZ(0);
        will-change: transform;
    }


</style>
