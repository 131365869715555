export const mixinCheetah = {
    data() {
      return {
        hostMap: {
          '.sitebuilder-publication.kub.app.builderall.io': {
            cheetah: 'https://api-sitebuilder.kub.app.builderall.io'
          },

          '.sitebuilder-publication.kub.app.builderall.info': {
            cheetah: 'https://api-sitebuilder.kub.app.builderall.info'     
          },
          
          'default': {
            cheetah: 'https://cheetah-api.builderall.com'            
          }
        }
      };
    },
    methods: {
      getCheetahApiUrl(host = null) {        
        
        if (host !== null) {
          const foundKey   = Object.keys(this.hostMap).find(key => host.includes(key));
          const hostConfig = this.hostMap[foundKey] || this.hostMap['default'];
          
          return hostConfig.cheetah;
        }

        return 'https://cheetah-api.builderall.com';

      }
    }
  };
  